.standingTable-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 5px 10px;
  .standingTable-group {
      display: flex;
      flex-direction: row;
      width: 48.5%;
      margin: 10px 10px 0;
      @media screen and (max-width: $medium_Device){
        width: 47.9%;
        margin: 10px 10px 0;
      }
      @media screen and (max-width: $tab_Device){
        width: 48.6%;
        margin: 10px 5px 0;
      }
      &.fullWidth{
        width: 100%;
        margin: 0;
      }
  }
  
  .groupStagingth{
    width: auto !important;
  }
}
.tableDefault.standingsTable {
      th:nth-child(2) {
        text-align: left;
        width: 150px;
    }
  tbody td:nth-child(2) {
      text-align: left;
  }
}


// .tableDefault.standingsTable {
//     tbody td:first-child {
//         text-align: left;
//     }
//     thead th {
//         background-color: #052754;
//         color: #fff;
  
//         &:first-child {
//             text-align: left;
//         }
//     }
//     .groupHeading {
//         font-size: 20px;
//         font-weight: 600;
//     }
//   }
