.tableDefault {
  border-collapse: collapse;
  width: 100%;
  background-color: #fff;
  line-height: 18px;
  font-size: 13px;
  overflow: hidden;
  font-family: "Montserrat", sans-serif;
  // transform: scale(1, 1.1);
  @media screen and (max-width: $tab_Device){
    font-size: 12px;
    line-height: 15px;
  }
  th, td {
    border-bottom: 1px solid #ddd;
    text-align: center;
  }
  thead th {
    padding: 12px 4px;
    background-color: #ddd;
    color: $dark-blue;
    vertical-align: top;
    font-size: 14px;
    @media screen and (max-width: $medium_Device){
      font-size: 13px;
    }
    @media screen and (max-width: $tab_Device){
      font-size: 12px;
      padding: 6px 4px;
    }
  }
  tbody td {
    color: #272727;
    padding: 0px 4px;
    height: 39px;
  }
  .playOff-Teams
  {
    width: 250px;
    text-align: left;
    @media screen and (max-width: $tab_Device){
      width: 150px;
    }
    .teamBold{
      font-weight: 600;
    }
  }
  
  .playOff-date
  {
    width: 140px;
    @media screen and (max-width: $medium_Device){
      width: 150px;
    }
    @media screen and (max-width: $tab_Device){
      width: 110px;
    }
  }
  .playOff-data
  {
    width: 250px;
    // text-align: left;
    @media screen and (max-width: $medium_Device){
      width: 150px;
    }
    @media screen and (max-width: $tab_Device){
      width: 110px;
    }
  }
  .playOff-leg
  {
    width: 180px;
  }
  .standings-player{
    width: 250px;
    @media screen and (max-width: $tab_Device){
      width: 175px;
    }
  }
  //only for Player Analysis tab
  &.playerAnalysis{
    thead th {
        border-bottom: solid 1px #bbb;
        vertical-align: middle;
      &:first-child {
        text-align: left;
        padding-left: 10px;
      }
    }
    tbody td {
      &:first-child {
        text-align: left;
        padding-left: 10px;
      }
    }
  }
  //only for Fixtures/Results tab
  &.fixtureResults{
    thead th {
      &:nth-child(4) {
        text-align: left;
        width: 180px;
      }
    }
    tbody td {
      &:nth-child(4) {
        text-align: left;
      }
    }
  }
  &.tableForGraph {
    background-color: transparent;
    /* max-width: 600px; */
    font-family: HelveticaCondensed;
    font-size: 14px;
    text-transform: uppercase;
    th {
      border-bottom: none;
      color: #fff;
    }
    td {
      border-bottom: none;
      color: #fff;

      &:first-child {
        text-align: left;
      }
    }
    thead {
      tr {
        border-bottom: 1px dotted #ddd;
      }
      th {
        padding: 8px;
        background-color: transparent;
        font-weight: normal;
      }
    }
    tbody td {
      padding: 0px 7px;
      height: 39px;
    }
  }
  &.table-stripped {
    th, td {
      border: 0px solid #ddd;
    }
    tbody tr {
      &:nth-child(odd) {
        background-color: #cae4fb;
      }
      &:hover {
        background-color: #d7dce9;
      }
    }
  }
  &.tableDefault-bg {
    background: #fff;
    td:nth-child(1), th:nth-child(1) {
      text-align: left;
    }
    thead th {
      background-color: #ddd;
      color: #001a69;
      padding: 12px 5px;
    }
    .whiteColumn-bg {
      display: flex;
      height: 40px;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      text-align: center;
    }
    .noBorder-td {
      border: none;
      padding: 0;
    }
    .teamsTo-score {
      width: 70px;
    }
    .fh-hilo {
      width: 80px;
    }
    .bothTo-score, .against {
      width: 100px;
    }
    .teams {
      width: 150px;
      
    }
  }
  &.tableSeasonal-stats {
    background-color: #273c6a;
    th {
      color: #fff;
      border-bottom: solid 1px #314977;
    }
    td {
      color: #fff;
      border-bottom: solid 1px #314977;
      &:nth-child(2) {
        width: 150px;
        // text-align: left;
        @media screen and (max-width: $medium_Device){
          width: auto;
        }
        @media screen and (max-width: $tab_Device){
          width: auto;
        }
      }
    }
    .counttd{
      text-align: center !important;
    }
    .for {
      width: 120px;
    }
    .against {
      width: 150px;
    }
    thead th {
      background-color: #292e3b;
      color: #fff;
      padding: 10px 4px;
      vertical-align: middle;
    }
    tbody tr:hover {
      background-color: none !important;
    }
  }
  &.tableSpaceDividor thead tr {
    &:last-child th, th[rowspan="2"] {
      border-bottom-color: #292e3b;
    }
  }
  &.keyIndicator {
    background-color: #273c6a;
    margin-top: 10px;
    th, td {
      color: #fff;
      border-bottom: solid 1px #314977;
      font-size: 16px;
      @media screen and (max-width: $tab_Device){
        font-size: 15px;
      }
    }
    thead{
      tr:first-child th{
        &:first-child {
          color: #29d9d7;
          //width: 250px;
        }
        &:last-child {
          color: #f17f80;
          //width: 250px;
        }
      }
      th {
        padding: 15px 0;
        //font-size: 18px;
        background-color: #292e3b;
        
      }

    } 

    tbody td {
      padding: 8px 0;
    }
  }
  &.tableHkjc-mode {
    line-height: 18px;
    color: #000;
    border-radius: 0 0 5px 5px;
    th, td {
      border-bottom: none;
    }
    thead th {
      padding: 8px;
      height: 28px;
      background-color: #eee;
      color: #000;
    }
    tr {
      border-bottom: solid 1px #eee;
      &:last-child {
        border-bottom: none;
      }
    }
  }
  &.tableDefault-hkjc-mode {
    line-height: 18px;
    color: #000;
    th, td {
      border-bottom: none;
    }
    thead th {
      padding: 8px;
      height: 28px;
      background-color: transparent;
      color: #000;
      border-bottom: 1px solid #ddd;
    }
  }
  .ShowMore{
    text-align: center;
    color: #fff;
    li{
      list-style: none;
      padding: 10px;
      width: 100%;
      font-weight: 600;
      cursor:pointer;
    }
  }
  .vertical-line{ 
    border: solid 2px #052754;
    height: 0px !important;
     }
}